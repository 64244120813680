.users-action {
    &__form {
        display: flex;
        flex-direction: column;
        row-gap: 16px;

        &-buttons {
            display: flex;
            column-gap: 24px;

            a,
            .button {
                width: 100%;
            }
        }

        .store-groups.hidden {
            display: none;
        }
    }
}
