
                    @use "src/Styles/basic/colors" as colors;
                    @use "src/Styles/basic/fonts" as fonts;
                    @use "src/Styles/basic/functions" as functions;
                    @use "src/Styles/basic/mixins" as mixins;
                
.donut {
  position: relative;
  display: flex;
  justify-content: space-between;
  gap: 20px;
  align-items: center;
  margin-top: 25px;
  width: 100%;
}
