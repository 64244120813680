
                    @use "src/Styles/basic/colors" as colors;
                    @use "src/Styles/basic/fonts" as fonts;
                    @use "src/Styles/basic/functions" as functions;
                    @use "src/Styles/basic/mixins" as mixins;
                
.dot {
  width: 14px;
  height: 14px;
  min-width: 14px;
  min-height: 14px;
  border-radius: 100%;
}