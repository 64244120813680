.table {
    //max-width: 1140px;
    &__header {
        display: flex;
        margin-bottom: 27px;

        .select {
            max-width: 257px;
        }
    }

    .icon {
        color: colors.$brown;
        transition: color 0.2s;

        &:not(.disabled):hover {
            color: colors.$brown-dark;
        }
    }

    &__counter {
        @include mixins.mainfont(400, 14, colors.$black, 18);
        opacity: 0.5;
        text-align: right;
    }

    &__actions {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        height: 100%;

        &-item {
            padding: 0 18px;
            height: 100%;
            &:not(:last-child) {
                border-right: 2px solid colors.$skin;
            }
            &:last-child {
                padding-right: 0;
            }
        }

        &-single {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            .table__actions-item {
                padding: 0 0 0 18px;
                border-left: 2px solid colors.$skin;
            }
        }
    }

    table {
        width: 100%;
        border-spacing: 0;

        background: colors.$white;

        @include mixins.mainfont(400, 14, colors.$black, 17);
        border-collapse: separate;
        margin-top: 2px;
        margin-bottom: 43px;

        thead {
            font-weight: 600;

            .header_cell {
                // &.active {
                //     // color: colors.$brown-dark;
                //     background-color: rgba(colors.$dark-gray, 0.1);
                //     border-radius: 5px;
                // }
                &.sortable {
                    cursor: pointer;

                    div {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                    }

                    .icon {
                        transition: 0.2s;
                        color: colors.$black;
                    }
                }

                &.asc {
                    .icon {
                        transform: scaleY(-1);
                    }
                }
            }

            td {
                padding: 16px 15px;
            }
        }

        & tbody tr {
            border-color: #d9d9d9;
            transition: background-color 0.2s;

            img {
                width: 80px;
                height: 80px;
                border-radius: 5px;
            }

            //&:hover {
            //    background-color: colors.$skin;
            //}

            &.deleted,
            &.blocked {
                background-color: colors.$skin-light;
                //border-color: colors.$skin;
            }
        }

        & tbody tr > td {
            padding: 16px 15px;
            border-top: 1px solid colors.$light-gray;
            border-bottom: 1px solid colors.$light-gray;
            position: relative;

            &.tooltip {
                .tooltip__content {
                    opacity: 0;
                    transition: 0.2s;
                    position: absolute;
                    z-index: 12;
                    left: 30px;
                    top: 70%;
                    width: fit-content;
                    max-width: 185px;
                    visibility: hidden;
                    padding: 10px;
                    background: #ffffff;
                    border: 1px solid rgba(182, 127, 127, 0.2);
                    box-shadow: 0 11px 26px -4px rgba(145, 158, 171, 0.24);
                    border-radius: 3px;
                    white-space: unset;
                    word-break: break-word;
                    word-wrap: break-word;
                }

                &:hover {
                    .tooltip__content {
                        opacity: 1;
                        visibility: visible;
                    }
                }
            }
            &:first-child {
                border-left: 1px solid colors.$light-gray;
                border-radius: 8px 0 0 8px;
                -moz-border-radius: 8px 0 0 8px;
                -webkit-border-radius: 8px 0 0 8px;
            }
            &:last-child {
                border-right: 1px solid colors.$light-gray;
                border-radius: 0 8px 8px 0;
                -moz-border-radius: 0 8px 8px 0;
                -webkit-border-radius: 0 8px 8px 0;
            }

            a {
                display: block;
                height: 100%;
                text-decoration: underline;
                color: colors.$black;
                // color: #c17e7e;
                // font-weight: 500;
                // padding: 0 0 0 18px;
                &:not(:last-child) {
                    border-right: 2px solid colors.$skin-2;
                    padding: 0 18px;
                }
            }
        }
    }
}
