
                    @use "src/Styles/basic/colors" as colors;
                    @use "src/Styles/basic/fonts" as fonts;
                    @use "src/Styles/basic/functions" as functions;
                    @use "src/Styles/basic/mixins" as mixins;
                
.whiffs_chart {
  .info {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &_cards {
      display: flex;
      gap: 20px;
    }

    &_filters {
      margin-bottom: 0;
    }
  }
}