
                    @use "src/Styles/basic/colors" as colors;
                    @use "src/Styles/basic/fonts" as fonts;
                    @use "src/Styles/basic/functions" as functions;
                    @use "src/Styles/basic/mixins" as mixins;
                
.switcher {
  display: flex;
  justify-content: space-between;
  align-items: center;

  column-gap: 16px;
  padding: 5px 13px;
  height: 46px;

  position: relative;

  background-color: colors.$skin-3;
  border-radius: 5px;

  &__item {
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
    cursor: pointer;
    padding: 0 14px;
    height: 30px;
    @include mixins.mainfont(500, 14, colors.$brown, 18);
  }

  &__selector {
    background-color: colors.$white;
    border-radius: 5px;
    height: calc(100% - 10px);
    top: 50%;
    transform: translate(0, -50%);
    width: 70px;
    position: absolute;
    z-index: 0;
    transition: 0.2s;
  }

  &__errors {
    border: 1px solid colors.$red;
  }
}

.errors {
  display: inline-block;
  margin-bottom: 5px;
  @include mixins.mainfont(500, 14, colors.$red, 18);
}