.chart_tooltip {
    background-color: colors.$white;
    padding: 16px 17px !important;
    border-radius: 3px;
    box-shadow: 0px 11px 26px -4px rgba(145, 158, 171, 0.24);
    border: 1px solid rgba(182, 127, 127, 0.2);

    display: flex;
    flex-direction: column;

    transition: 0.2s;

    position: absolute;

    &__title {
        display: flex;
        gap: 8px;
        align-items: center;
        margin-bottom: 7px;

        &-circle {
            border-radius: 50%;
            width: 10px;
            height: 10px;
        }

        p {
            @include mixins.mainfont(700, 14px, colors.$black, 18px);
        }
    }

    &__content {
        display: flex;
        justify-content: space-between;
        column-gap: 39px;

        p {
            @include mixins.mainfont(500, 14px, colors.$black, 18px);
        }
    }
}
