.widget-chat {
    position: fixed;
    bottom: 29px;
    right: 34px;
    padding: 11px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: colors.$brown;
    border-radius: 50%;
    cursor: pointer;
    z-index: 10;
}
