.filters {
  max-width: 100%;
  width: fit-content;
  display: flex;
  align-items: flex-end;
  gap: 15px;
  margin-bottom: 26px;
  flex-wrap: wrap;

  input[placeholder="Search"] {
    width: 360px;
  }

  input:not([placeholder="Search"]) {
    width: 140px;
  }

  .datepicker {
    //&__inputs {
    //  align-items: stretch;
    //}
    //
    &__input {
      height: 46px;
    }
  }
}
