
                    @use "src/Styles/basic/colors" as colors;
                    @use "src/Styles/basic/fonts" as fonts;
                    @use "src/Styles/basic/functions" as functions;
                    @use "src/Styles/basic/mixins" as mixins;
                
.circle_divisions {
  display: flex;
  flex-direction: column;
  gap: 50px;
  width: 100%;
  align-items: center;
  font-family: fonts.$mainFont;

  &_chart {
    position: relative;
  }
}