.station-firmware {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;

  &__item {
    width: 100%;
    max-width: 450px;
    display: flex;
    flex-direction: column;
    row-gap: 24px;
    padding: 28px;
    box-shadow: 0px -5px 19px rgba(0, 0, 0, 0.02), -4px 11px 26px -4px rgba(145, 158, 171, 0.24);
    border-radius: 3px;

    p {
      display: flex;
      align-items: center;
      column-gap: 9px;
      @include mixins.mainfont(400, 14, colors.$black, 18);
    }

    & .license_key {
      display: block;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }

    & .error {
      color: colors.$red;
    }

    & .edit {
      margin-top: 10px;
    }
  }

  &_export {
    justify-content: center;
  }
}