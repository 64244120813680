* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

#root {
    // display: flex;
    // flex-flow: column nowrap;
    height: fit-content;
    min-height: 100vh;
}

h1 {
    @include mixins.mainfont(700, 24, colors.$black, 31);
    margin-bottom: 31px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .button {
        width: 168px;
    }

    & > div {
        display: flex;
        align-items: center;
        column-gap: 22px;
        &.back {
            cursor: pointer;
            column-gap: 2px;
            & > .icon {
                transform: rotate(180deg);
            }
            & > span {
                @include mixins.mainfont(500, 14, colors.$black, 22);
            }
        }
    }
}

h2 {
    @include mixins.mainfont(700, 24, colors.$black, 31);
}

h3 {
    @include mixins.mainfont(600, 16, colors.$black, 21);
}

h4 {
    @include mixins.mainfont(500, 15, colors.$black, 20);
}

h5 {
    @include mixins.mainfont(500, 14, colors.$black, 18);
}

.action-form {
    display: flex;
    flex-direction: column;
    row-gap: 12px;

    &.disabled {
        opacity: 0.5;
        pointer-events: none;
        user-select: none;
    }

    &.hidden {
        display: none;
    }

    &-buttons {
        display: flex;
        column-gap: 12px;

        a,
        .button {
            width: 100%;
        }

        &.additional {
            justify-content: space-between;
            max-width: 358px;
            .button {
                max-width: 130px;
            }
        }
    }

    .chechbox_error {
        @include mixins.mainfont(500, 15, colors.$red, 20);
    }
}

.entity-page {
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    @include mixins.mainfont(400, 14, colors.$black, 17);

    &__container {
        display: flex;
        div {
            width: 100%;
        }
        img {
            width: 116px;
            border: 1px solid colors.$light-gray;
            border-radius: 8px;
        }
    }

    &__item {
        display: flex;
        //grid-template-columns: repeat(2, 1fr);
        align-items: flex-start;
        padding: 16px 16px;
        border: 1px solid colors.$light-gray;
        border-radius: 8px;

        & > div {
            width: 100%;
            &.bold {
                font-weight: 700;
                max-width: 430px;
            }
        }
    }
}
