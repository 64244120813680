
                    @use "src/Styles/basic/colors" as colors;
                    @use "src/Styles/basic/fonts" as fonts;
                    @use "src/Styles/basic/functions" as functions;
                    @use "src/Styles/basic/mixins" as mixins;
                
.Card {
    border-radius: 8px;
    padding: 8px;
    background-color: #eeebe9;
    display: flex;
    flex-direction: column;
    gap: 8px;
    font-family: fonts.$mainFont;
    max-width: 320px;
}

.Info {
    display: flex;
    align-items: center;
    gap: 12px;
}

.Id {
    font-size: 18px;
    font-weight: 400;
}

.Index {
    border-radius: 10px;
    color: #ffffff;
    font-size: 20px;
    font-weight: 700;
    height: 36px;
    min-width: 36px;
    display: flex;
    align-items: center;
    justify-content: center;

    &_Green {
        background: #a3ca74cc;
    }

    &_Yellow {
        background: #f5cc61;
    }

    &_Orange {
        background: #f6aa59;
    }

    &_Red {
        background: #ec6b77;
    }
}

.Values {
    display: flex;
    align-items: center;
    gap: 16px;
}

.Value {
    display: flex;
    align-items: center;
    gap: 8px;
}

.Count {
    font-size: 16px;
    font-weight: 400;
}

.Products {
    font-weight: 400;
    font-size: 14px;
    word-break: normal;
    overflow-wrap: anywhere;
}
