.whiffs_circle {
  margin-top: 28px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 40px;
  justify-content: center;
  font-family: fonts.$mainFont;
  text-align: center;

  .visx-legend {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
    padding: 0 45px;

    &-item {
      display: flex;
      align-items: center;
      column-gap: 6px;
    }
  }

  &__statistics {
    width: 100%;
    padding-top: 22px;
    border-top: 2px solid colors.$skin-2;

    display: flex;
    gap: 20px;
    justify-content: space-evenly;

    &-item {
      width: 70px;

      h3 {
        @include mixins.mainfont(500, 16px, colors.$black);
      }

      p {
        @include mixins.mainfont(500, 14px, rgba(colors.$black, 0.5));
      }
    }
  }
}
