.roles-action {
    &__form {
        //table {
        //    min-width: 592px;
        //    margin-top: 33px;
        //    width: 100%;
        //
        //    font-family: fonts.$mainFont;
        //    font-style: normal;
        //    font-weight: 500;
        //    font-size: 14px;
        //    line-height: 17px;
        //
        //    border-spacing: 0;
        //    border-collapse: collapse;
        //
        //    thead {
        //        color: #b4b4b4;
        //        text-align: center;
        //    }
        //
        //    tbody {
        //        color: #212121;
        //        border-collapse: collapse;
        //
        //        tr {
        //            border-bottom: 1px solid #b4b4b4;
        //            td {
        //                padding: 9px 0;
        //            }
        //        }
        //
        //        .checkbox {
        //            margin: 0 auto;
        //        }
        //    }
        //}
        table {
            min-width: 592px;
        }
        &-buttons {
            display: flex;
            column-gap: 24px;

            a,
            .button {
                width: 100%;
            }
        }
    }
}
