.board {
    display: flex;
    flex-direction: column;
    gap: 15px;

    h1,
    h2,
    h3,
    h4,
    h5,
    p {
        margin: 0;
    }

    &_line {
        display: flex;
        gap: 20px;
        justify-content: space-between;
        // &:not(:last-child) {
        //     margin-bottom: 15px;
        // }
    }

    &_item {
        width: 100%;
        padding: 28px 20px;
        background-color: colors.$white;
        border-radius: 10px;

        &__header {
            display: flex;
            align-items: center;
            justify-content: space-between;

            h3 {
                margin-bottom: 22px;
            }

            &-more {
                display: flex;
                align-items: center;
                cursor: pointer;
                @include mixins.mainfont(500, 14, colors.$black, 22);
            }
        }

        &.head {
            padding: 22px 20px;
            // margin-bottom: 15px;
            h1 {
                margin-bottom: 31px;
            }
            .filters {
                margin-bottom: 0;
            }

            &.additional {
                margin-bottom: 0;
                border-radius: 10px 10px 0 0;
            }
            &.sticky {
                position: sticky;
                top: 15px;
                z-index: 20;
                transition: box-shadow 0.2s, border-radius 0.2s;
                &.shadow {
                    box-shadow: 0 8px 33px rgba(0, 0, 0, 0.1);
                }
            }
        }

        &.third {
            width: calc(calc(100% / 3) - 10px);
        }

        &.two-thirds {
            width: calc(100% - calc(100% / 3));
        }
    }
}
