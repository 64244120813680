.cart {
    position: fixed;
    height: 100vh;
    width: 100vw;
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    z-index: 10;
    overflow-x: scroll;
    @include mixins.hideScrollbar;
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.2s, visibility 0.2s;

    background: rgba(0, 0, 0, 0.75);

    &.open {
        visibility: visible;
        opacity: 1;
        
        .cart__content {
            //max-width: 445px;
        }
    }

    &__heading {
        padding: 11.5px 0;
        width: 100%;
        display: flex;
        justify-content: center;
        background: #d9d9d9;
        margin-bottom: 37px;

        font-family: fonts.$mainFont;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        color: #000000;
    }

    &__content {
        height: 100%;
        //max-width: 0;
        width: 445px;
        background-color: #e6e6e6;

        display: flex;
        flex-direction: column;
        justify-content: space-between;

        transition: max-width .2s;

        &-top {
            display: flex;
            flex-direction: column;
        }

        &-bottom {
            padding: 20px 25px;
            display: flex;
            flex-direction: column;
            row-gap: 20px;
            background-color: #d9d9d9;

            p {
                display: flex;
                align-items: center;
                justify-content: space-between;
            }
            & > span {
                margin: 0 auto;

                font-family: fonts.$mainFont;
                font-style: normal;
                font-weight: 500;
                font-size: 12px;
                line-height: 15px;
                color: #8e8e8e;

                cursor: pointer;
            }
        }

        &-items {
            padding: 0 9px;
            display: flex;
            flex-direction: column;
            row-gap: 8px;

            &-total {
                font-family: fonts.$mainFont;
                font-style: normal;
                font-weight: 500;
                font-size: 12px;
                line-height: 15px;
                color: #8e8e8e;
                text-align: right;
            }
        }
    }

    &__item {
        position: relative;
        padding: 18px;
        width: 100%;
        background: #d9d9d9;
        border-radius: 5px;

        display: flex;
        column-gap: 45px;
        justify-content: space-between;

        cursor: default;

        &-delete {
            position: absolute;
            top: 5px;
            right: 9px;
            cursor: pointer;
        }

        &-icon {
            height: 72px;
            min-width: 72px;
            background: #717171;
            border-radius: 5px;
        }

        &-right {
            min-width: 50px;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
        }

        &-name,
        &-price {
            font-family: fonts.$mainFont;
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 17px;
            color: #000000;
        }

        &-name {
            margin-bottom: 9px;
        }

        &-description {
            font-family: fonts.$mainFont;
            font-style: normal;
            font-weight: 400;
            font-size: 10px;
            line-height: 100%;
            color: #b4b4b4;
        }

        &-count {
            display: flex;

            p {
                width: 24px;
                text-align: center;
                background-color: #939393;
            }

            span {
                cursor: pointer;
            }
        }
    }
}
