
                    @use "src/Styles/basic/colors" as colors;
                    @use "src/Styles/basic/fonts" as fonts;
                    @use "src/Styles/basic/functions" as functions;
                    @use "src/Styles/basic/mixins" as mixins;
                
.inputWrapper {
  width: fit-content;
}

.lingualInput {
  width: fit-content;
  min-width: 500px;
  max-width: 500px;
}