
                    @use "src/Styles/basic/colors" as colors;
                    @use "src/Styles/basic/fonts" as fonts;
                    @use "src/Styles/basic/functions" as functions;
                    @use "src/Styles/basic/mixins" as mixins;
                
.form {
    max-width: 360px;
    display: flex;
    flex-direction: column;
    row-gap: 12px;
}
