
                    @use "src/Styles/basic/colors" as colors;
                    @use "src/Styles/basic/fonts" as fonts;
                    @use "src/Styles/basic/functions" as functions;
                    @use "src/Styles/basic/mixins" as mixins;
                
.formItem {
  background-color: colors.$white;
  padding: 22px 20px 30px;
  border-radius: 10px;
}