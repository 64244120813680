
                    @use "src/Styles/basic/colors" as colors;
                    @use "src/Styles/basic/fonts" as fonts;
                    @use "src/Styles/basic/functions" as functions;
                    @use "src/Styles/basic/mixins" as mixins;
                
.buttons {
  display: flex;
  column-gap: 14px;
  margin: 0;
}

.button {
  width: 130px;
  height: 40px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
}