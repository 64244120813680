.form__buttons {
    display: flex;
    justify-content: space-between;
    max-width: 360px;
    margin-top: 22px;

    a,
    .button {
        width: 130px;
        height: 40px;
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        text-decoration: none;
    }
}
