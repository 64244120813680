.input.select {
    @include mixins.mainfont(500, 14, colors.$black, 18);

    .select {
        position: relative;
        &__search {
            position: absolute;
            top: 13px;
            right: 15px;
            color: colors.$brown;
        }
        &__icon {
            position: absolute;
            top: 23px;
            right: 15px;
            transform: translateY(-50%);
            pointer-events: none;
            transition: transform 0.2s;
            color: colors.$brown;
        }
        &__options {
            padding: 0 5px;
            margin-top: 3px;
            box-sizing: border-box;
            background: colors.$skin;
            border-top: none;
            border-radius: 5px;
            max-height: 0px;
            overflow: hidden;
            transition: height 0.2s, max-height 0.2s, border-color 0.2s;
            z-index: 5;
            display: flex;
            flex-direction: column;
            row-gap: 3px;

            @include mixins.hideScrollbar();
        }
        &__label {
            @include mixins.mainfont(500, 13, rgba(colors.$black, 0.5), 17);
            display: flex;
            align-items: center;
            column-gap: 2px;
            width: fit-content;
            padding-bottom: 5px;

            span {
                color: colors.$red;
            }
        }
        &__option {
            box-sizing: border-box;
            min-height: 40px;
            padding: 11px;
            display: flex;
            align-items: center;
            // padding-left: 15px;
            font-family: fonts.$mainFont;
            font-weight: 400;
            font-size: 14px;
            border-radius: 8px;
            overflow: hidden;
            white-space: nowrap;

            cursor: pointer;
            transition: background-color 0.2s;

            &:hover,
            &.selected {
                background: rgba(182, 127, 127, 0.49);
            }

            &:last-child {
                border-bottom: none;
            }
        }
        &__preloader {
            width: 15px;
            height: 15px;
            // border-top: 3px solid colors.$gray;
            // border-right: 3px solid colors.$light-gray;
            // border-bottom: 3px solid colors.$light-gray;
            // border-left: 3px solid colors.$light-gray;
            border-radius: 50%;
            margin: 10px auto;

            animation: preloader infinite 0.5s;
        }
        &__not-found {
            font-family: fonts.$mainFont;
            font-weight: 400;
            font-size: 14px;
            text-align: center;
            // color: colors.$black;
            padding: 15px 0;
        }
        &__list {
            margin-top: 10px;
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            column-gap: 7px;
            row-gap: 9px;
            max-width: 360px;
            &-item {
                padding: 11px;
                background: #ffffff;
                border: 1px solid rgba(182, 127, 127, 0.2);
                border-radius: 20px;
                @include mixins.mainfont(500, 14, colors.$black, 18);
                display: flex;
                column-gap: 10px;
                .icon {
                    color: colors.$brown;
                    cursor: pointer;
                    flex-shrink: 0;
                }
            }
        }
        &__placeholder {
            pointer-events: none;
            overflow: hidden;
            position: absolute;
            top: 50%;
            left: 13px;
            display: flex;
            align-items: center;
            column-gap: 2px;
            @include mixins.mainfont(500, 14, colors.$black, 18);
            max-width: 85%;
            transform: translateY(-50%);
            //
            p {
                opacity: 0.5;
                color: colors.$black !important;
            }
            span {
                color: colors.$red;
            }
            &.hide {
                display: none;
            }
        }

        &__input {
            position: relative;
        }
    }

    input {
        cursor: pointer;
        transition: border-color 0.2s;
        padding-right: 45px;

        &.error {
            border: 1px solid colors.$red;
            // &::placeholder {
            //     color: colors.$red;
            // }
        }
    }

    &.open {
        .select {
            &__icon {
                transform: translateY(-50%) scaleY(-1);
            }
            &__options {
                border-color: #d9d9d9;
                height: fit-content;
                max-height: 175px;
                overflow-y: scroll;
            }
        }
    }
}

@keyframes preloader {
    0% {
        transform: rotate(0);
    }

    25% {
        transform: rotate(90deg);
    }

    50% {
        transform: rotate(180deg);
    }

    75% {
        transform: rotate(270deg);
    }

    100% {
        transform: rotate(360deg);
    }
}
