.not-found {
  background-color: colors.$skin;
  width: 100vw;
  height: 100vh;

  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    h1 {
      @include mixins.mainfont(700, 75, colors.$black, 98);
      text-align: center;
      justify-content: center;
      margin-bottom: 4px;
      width: 100%;
    }
    p {
      @include mixins.mainfont(500, 20, colors.$black, 26);
      margin-bottom: 44px;
      text-align: center;
    }
    span {
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      @include mixins.mainfont(500, 14, colors.$black, 22);
      svg {
        color: colors.$brown;
      }
    }
  }
}