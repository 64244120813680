.checkbox {
    position: relative;
    width: fit-content;
    height: fit-content;
    background: none;
    border: none;
    outline: none;
    display: flex;
    align-items: center;
    column-gap: 6px;

    cursor: pointer;

    &.disabled {
        cursor: default;
    }

    input {
        position: absolute;
        width: 100%;
        height: 100%;
        opacity: 0;
    }

    &__block {
        width: 25px;
        height: 25px;

        display: flex;
        align-items: center;
        justify-content: center;

        border-radius: 5px;
        background-color: colors.$skin;

        .icon {
            opacity: 0;
            pointer-events: none;
            color: colors.$brown;
            transition: opacity 0.1s;
        }

        &.checked {
            .icon {
                opacity: 1;
            }
        }
    }

    &__text {
        @include mixins.mainfont(400, 12, colors.$black, 22);
    }
}
