.language-select {
    position: relative;
    width: fit-content;

    &__visible {
        cursor: pointer;
        display: flex;
        align-items: center;
        column-gap: 10px;
        @include mixins.mainfont(500, 14, colors.$black, 18);

        & > svg:not(.icon) {
            width: 22px;
            height: 22px;
        }
    }
    &__list {
        position: absolute;
        z-index: 21;
        top: 0;
        right: 0;
        border-color: transparent;
        display: flex;
        flex-direction: column;
        row-gap: 19px;
        //background-color: transparent;
        background-color: colors.$skin;
        max-width: 200px;
        max-height: 380px;
        border-radius: 5px;

        opacity: 0;
        padding: 0;
        max-height: 0;
        overflow: hidden;
        visibility: hidden;
        transition: 0.2s;

        &.open {
            padding: 24px 10px;
            border: 1px solid rgba(0, 0, 0, 0.05);
            box-shadow: 1px 0 20px rgba(0, 0, 0, 0.1);
            max-height: none;
            visibility: visible;
            cursor: default;
            opacity: 1;
        }

        &_item {
            padding: 9px 27px 9px 24px;
            display: flex;
            align-items: center;
            column-gap: 18px;
            cursor: pointer;
            border-radius: 8px;
            transition: background-color 0.2s;
            @include mixins.mainfont(400, 18, colors.$black, 23);

            & > svg {
                flex-shrink: 0;
                width: 22px;
                height: 22px;
            }

            & > span {
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
            }

            &:hover {
                background-color: rgba(182, 127, 127, 0.49);
            }
        }
    }
}
