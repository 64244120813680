
                    @use "src/Styles/basic/colors" as colors;
                    @use "src/Styles/basic/fonts" as fonts;
                    @use "src/Styles/basic/functions" as functions;
                    @use "src/Styles/basic/mixins" as mixins;
                
.tab_button {
  padding: 11px 16px;
  background: #ffffff;
  border: 1px solid rgba(182, 127, 127, 0.2);
  border-radius: 8px;
  position: relative;
  cursor: pointer;
  height: 38px;

  @include mixins.mainfont(500, 12, colors.$brown, 17);
  text-decoration: none;

  &.active,
  &:hover:not(&.disabled) {
    border-color: transparent;
    color: colors.$black;
    background-color: colors.$skin-3;
    transition: border-color, color, background-color 0.2s;
  }

  &.disabled {
    cursor: initial;
    opacity: 50%;
  }
}