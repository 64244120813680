.datepicker__input {
    width: 140px;
    height: 40px;
    position: relative;

    &.current {
        & > input {
            background-color: colors.$skin-3;
        }
    }

    & > {
        input {
            @include mixins.mainfont(500, 14, colors.$black);
            background-color: colors.$white;
            border: 1px solid rgba(colors.$skin-4, 0.2);
            border-radius: 5px;
            padding: 0 10px;
            display: flex;
            align-items: center;
            height: 100%;
            text-transform: uppercase;
            transition: 0.2s;

            &::placeholder {
                color: colors.$black;
                opacity: 0.5;
                text-transform: none;
            }
        }

        svg {
            z-index: 1;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 10px;
            color: colors.$brown;
        }
    }
}
