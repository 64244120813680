.brands-action {
  &__form {
    display: flex;
    flex-direction: column;
    row-gap: 12px;

    &-buttons {
      display: flex;
      column-gap: 12px;

      a,
      .button {
        width: 100%;
      }
    }
    &-error {
      font-family: fonts.$mainFont;
      color: colors.$red;
      text-align: center;
    }
  }
}