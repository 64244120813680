.button {
    padding: 15.5px 0;
    width: 100%;

    font-family: fonts.$mainFont;
    font-style: normal;
    font-weight: 700;
    font-size: 13px;
    line-height: 17px;
    text-transform: uppercase;
    color: colors.$brown;

    border: none;
    border-radius: 22px;
    cursor: pointer;

    transition: transform 0.2s;
    text-decoration: none;
    text-align: center;

    &:not(.disabled):active {
        transform: scale(0.95);
    }

    &.disabled {
        opacity: 0.5;
        cursor: not-allowed;
    }

    &.brown {
        background: colors.$brown;
        color: colors.$white;
        transition: background 0.2s;

        &:not(.disabled):hover {
            background: colors.$brown-dark;
        }
    }

    &.dark-gray {
        font-family: fonts.$mainFont;
        font-size: 20px;
        line-height: 24px;

        color: #ffffff;

        background: #646464;
    }

    &.transparent {
        border: 1px solid colors.$brown;
        background: none;
        color: colors.$brown;
        transition:
            color,
            background 0.2s;

        &:not(.disabled):hover {
            background: colors.$brown-dark;
            color: colors.$white;
        }
    }
}
