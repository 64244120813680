.store-group {
    &__info {
        padding: 30px 20px;
        background: #ffffff;
        box-shadow: 0px -5px 19px rgba(0, 0, 0, 0.02), -4px 11px 26px -4px rgba(145, 158, 171, 0.24);
        border-radius: 3px;
        display: flex;
        column-gap: 20px;

        font-family: fonts.$mainFont;
        font-style: normal;
        font-size: 14px;
        line-height: 17px;
        color: colors.$black;

        div {
            display: flex;
            flex-direction: column;
            row-gap: 5px;

            h2 {
                font-weight: 600;
            }

            p {
                font-weight: 400;
            }
        }
    }
}
