
                    @use "src/Styles/basic/colors" as colors;
                    @use "src/Styles/basic/fonts" as fonts;
                    @use "src/Styles/basic/functions" as functions;
                    @use "src/Styles/basic/mixins" as mixins;
                
.analytic_card_info {
  max-width: 140px;
  padding: 15px;
  border-radius: 20px;
  border: 1px solid #F0E5E5;

  @include mixins.mainfont(500, 13, colors.$black);

  .title {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
    gap: 10px;

    @include mixins.mainfont(700, 22, colors.$black);
  }
}