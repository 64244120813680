.sidebar {
    padding: 20px 0;
    // height: 100vh;
    width: 100%;
    max-width: 249px;
    background: colors.$black;
    overflow-x: scroll;
    @include mixins.hideScrollbar;

    &__logo {
        //height: 26.24px;
        margin-bottom: 80px;
        margin-left: 30px;
        display: flex;
        align-items: center;

        svg {
            width: 124px;
        }
    }

    &__links {
        display: flex;
        flex-direction: column;
        row-gap: 8px;

        &-nested {
            display: flex;
            flex-direction: column;
            align-items: stretch !important;

            cursor: pointer;
            position: relative;

            @include mixins.mainfont(400, 16, colors.$white);
            text-decoration: none;

            &.active {
                div {
                    & > .icon {
                        transform: rotate(180deg);
                    }

                    &::before {
                        content: "";
                        position: absolute;
                        background: colors.$skin-2;
                        width: 4px;
                        height: 100%;
                        left: 0;
                        top: 0;
                    }
                }

                nav {
                    max-height: 1000px;
                }
            }

            div {
                padding: 10px 30px;
                display: flex;
                justify-content: space-between;
                position: relative;

                p {
                    display: flex;
                    align-items: center;
                    column-gap: 10px;
                }

                & > .icon {
                    transform: rotate(0);
                    transition: transform 0.2s;
                }

                &:hover {
                    &::before {
                        content: "";
                        position: absolute;
                        background: colors.$skin-2;
                        width: 4px;
                        height: 100%;
                        left: 0;
                        top: 0;
                    }
                }
            }

            nav {
                padding: 0 20px;
                display: flex;
                flex-direction: column;
                row-gap: 3px;
                max-height: 0;
                overflow: hidden;
                transition: max-height 0.2s;

                a {
                    cursor: pointer;

                    padding: 10px 0 10px 46px;
                    position: relative;

                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    column-gap: 12px;

                    @include mixins.mainfont(400, 16, colors.$white);
                    text-decoration: none;
                    border-radius: 8px;
                    .icon {
                        visibility: hidden;
                        position: absolute;
                    }

                    &.active,
                    &:hover {
                        background: colors.$dark-gray;
                    }

                    &.active {
                        .icon {
                            visibility: visible;
                            top: 50%;
                            transform: translateY(-50%);
                            left: 15px;
                        }
                    }
                }
            }
        }

        & > a {
            cursor: pointer;

            padding: 10px 30px;
            position: relative;

            display: flex;
            align-items: center;
            justify-content: space-between;

            @include mixins.mainfont(400, 16, colors.$white);
            text-decoration: none;
            p {
                display: flex;
                align-items: center;
                column-gap: 10px;
            }

            &.active,
            &:hover {
                &::before {
                    content: "";
                    position: absolute;
                    background: colors.$skin-2;
                    width: 4px;
                    height: 100%;
                    left: 0;
                    top: 0;
                }
            }
        }
    }
}
