@keyframes barAnimate {
    0% {
        transform: translateY(100%);
    }
    100% {
        transform: translateY(0%);
    }
}

@keyframes barAnimateHor {
    0% {
        transform: translateX(-100%);
    }
    100% {
        transform: translateX(0%);
    }
}

.chart_bars {
    .visx-bar {
        transition: 0.2s;
        cursor: pointer;
        animation: barAnimate 1s forwards;

        &.horisontal {
            animation: barAnimateHor 1s forwards;
        }

        &:hover {
            fill: var(--hover-color);
        }
    }

    &-label {
        @include mixins.mainfont(500, 14, rgba(colors.$black, 0.5), 18);
    }

    @import "../Tools/Axis/styles";
}
