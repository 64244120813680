.pagination {
    display: flex;
    align-items: center;
    column-gap: 6px;

    &.hide {
        visibility: hidden;
    }

    span {
        cursor: pointer;
        padding: 4px 11.6px;

        @include mixins.mainfont(400, 16, rgba(33, 33, 33, 0.48), 24);
        border-radius: 8px;

        transition: background 0.1s, color 0.1s, font-weight 0.1s;

        @include mixins.noselect;

        &.active {
            font-weight: 500;
            color: colors.$black;
            background: colors.$skin;
        }
    }
    .icon {
        color: colors.$brown;
    }
}
