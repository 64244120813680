.custom-scroll {
    position: relative;
    overflow: hidden;

    &.hidden_thumb {
        .custom-scroll {
            &__scroll-box {
                width: 0;
                visibility: hidden;
            }

            &__content {
                margin-right: 0;
            }
        }
    }

    &__scroll-box {
        position: absolute;
        right: 8px;
        top: 14px;
        height: calc(100% - 30px);
        width: 8px;
    }

    &__scrollbar {
        background: rgba(182, 127, 127, 0.2);
        border-radius: 10px;
        width: 8px;

        &-thumb {
            position: absolute;
            width: 100%;
            background: #a16464;
            border-radius: 10px;
            right: 0px;
            cursor: pointer;

            // height: calc(100% / );
            // transition: top 0.05s;
        }
    }

    &__content {
        max-height: inherit;
        overflow-y: scroll;
        margin-right: 18px;

        @include mixins.hideScrollbar;
    }
}
