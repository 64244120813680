
                    @use "src/Styles/basic/colors" as colors;
                    @use "src/Styles/basic/fonts" as fonts;
                    @use "src/Styles/basic/functions" as functions;
                    @use "src/Styles/basic/mixins" as mixins;
                
.item {
    display: flex;
    align-items: center;
    height: 100%;
    width: 100%;
    column-gap: 78px;
    padding: 28px 20px 28px 72px;
    border-radius: inherit;

    h2 {
        margin-bottom: 10px;
    }

    &_link {
        text-decoration: none;

        transition: box-shadow 0.2s;

        &:hover,
        &:focus-visible {
            box-shadow: 0px -5px 19px rgba(0, 0, 0, 0.02), -4px 11px 26px -4px rgba(145, 158, 171, 0.24);
        }
    }
}

.block {
    padding: 0;
}
