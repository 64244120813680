
                    @use "src/Styles/basic/colors" as colors;
                    @use "src/Styles/basic/fonts" as fonts;
                    @use "src/Styles/basic/functions" as functions;
                    @use "src/Styles/basic/mixins" as mixins;
                
.text {
  margin-bottom: 25px;

  @include mixins.mainfont(700, 18, colors.$black)
}

.required {
  position: relative;
  top: -2px;
  right: -2px;

  color: colors.$red;
}
