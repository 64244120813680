
                    @use "src/Styles/basic/colors" as colors;
                    @use "src/Styles/basic/fonts" as fonts;
                    @use "src/Styles/basic/functions" as functions;
                    @use "src/Styles/basic/mixins" as mixins;
                
.analytic_chart_filter_info {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &_cards {
    display: flex;
    gap: 20px;
  }
}
