.breadcrumbs {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    width: fit-content;
    @include mixins.mainfont(500, 16, colors.$black, 21);

    &-item {
        text-decoration: none;
        margin: 0 2px;
        cursor: default;

        &:not(:first-child) {
            &::before {
                content: "/";
                margin-right: 4px;
                color: colors.$black;
            }
        }

        &.link {
            color: colors.$black;
            cursor: pointer;
        }
    }
}
