
                    @use "src/Styles/basic/colors" as colors;
                    @use "src/Styles/basic/fonts" as fonts;
                    @use "src/Styles/basic/functions" as functions;
                    @use "src/Styles/basic/mixins" as mixins;
                
.Filters {
    width: 100%;
    display: flex;
    align-items: stretch;
    gap: 15px;
    margin-bottom: 26px;
    flex-wrap: wrap;
}
