.modal {
	position: fixed;
	height: 100vh;
	width: 100vw;
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 50;
	top: 0;
	left: 0;

	opacity: 0;
	visibility: hidden;
	transition: opacity 0.2s, visibility 0.2s;

	&.open {
		opacity: 1;
		visibility: visible;
	}

	&__overlay {
		width: 100%;
		height: 100%;
		background: rgba(0, 0, 0, 0.75);
	}

	&__window {
		position: absolute;
		z-index: 11;
		background-color: colors.$white;
		border-radius: 10px;
	}

	@import "./Dialogs/Unsaved/styles";
	@import "./Dialogs/Info/styles";
}
