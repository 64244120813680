.page.data {
  .form__buttons {
    .button {
      padding: 13px 38px;
    }
  }
}

.product-price {
  display: flex;
  column-gap: 13px;
  align-items: flex-end;
  position: relative;
  width: fit-content;
  flex-grow: 1;
  transition: 0.2s;

  .input {
    width: 215px;
  }

  input {
    border: 1px solid transparent;

    transition: border-color 0.2s;
  }

  .select {
    width: 130px;
  }

  .icon {
    color: colors.$brown;
    margin-bottom: 12px;
  }

  &.error {
    input {
      border-color: colors.$red !important;
    }

    .product-price__error {
      @include mixins.mainfont(500, 16, colors.$red);
      margin-bottom: 16px;
      display: block;
    }
  }
}
