.multi-lang-input {
    &:not(.textarea):not(.full) {
        .multi-lang-input {
            &__input-block {
                width: 80%;
            }
        }
    }

    &.full {
        .multi-lang-input {
            &__input-block {
                width: 100%;
            }
        }
    }

    .input__content {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        column-gap: 8px;
        align-items: flex-end;

        .language-select {
            min-width: fit-content;
            width: 20%;
            margin-bottom: 15px;
            flex-grow: 1;
        }

        // .input-block__label {
        //     width: 367px;
        //     overflow: overlay;
        // }
    }

    &.textarea {
        height: 260px;

        .input__content {
            display: block;
            position: relative;

            .language-select {
                width: fit-content;
                position: absolute;
                background-color: colors.$white;
                padding: 0 2px 0 5px;
                height: 46px;
                display: flex;
                align-items: center;
                top: calc(100% - 4px);

                &__list {
                    right: initial;
                    left: 0;
                }
                // border-radius: 0px 5px 0 5px;
            }
            .textareaWrapper {
                padding: 13px 12px;

                width: 100%;
                height: 200px;

                background: colors.$skin;
                border-radius: 5px;
                border: 1px solid transparent;

                &.errors {
                    border: 1px solid colors.$red;
                }

                textarea {
                    padding: 0 12px 0 0 ;
                    width: 100%;
                    height: 100%;
                    border: none;
                    @include mixins.mainfont(500, 15, colors.$black, 20);
                    outline: none;

                    &::placeholder {
                        @include mixins.mainfont(500, 14, colors.$black, 18);
                        opacity: 0.5;
                    }
                    &::-webkit-scrollbar {
                        background: rgba(182, 127, 127, 0.2);
                        border-radius: 10px;
                        width: 8px;
                    }
                    &::-webkit-scrollbar-thumb {
                        background: #a16464;
                        border-radius: 10px;
                    }
                }
            }
        }

        &.top {
            display: flex;
            width: 100%;
            align-items: flex-end;

            .input__content {
                width: 100%;

                .language-select {
                    bottom: calc(100% - 4px);
                    top: auto;
                }
            }
        }
    }
}
