.tooltip_global {
    padding: 10px;
    background: #ffffff;
    border: 1px solid rgba(182, 127, 127, 0.2);
    box-shadow: 0 11px 26px -4px rgba(145, 158, 171, 0.24);
    border-radius: 3px;
    transition: opacity 1s;
    white-space: unset;
    word-break: break-word;
    word-wrap: break-word;
    z-index: 20;
    // transform: translate(20px, 20px);
    max-width: 300px;

    @include mixins.mainfont(400, 14, colors.$black);
}
