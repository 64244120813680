.profile {
    &-wrapper{
        position: relative;

        &__alert {
            position: absolute;
            top: 0;
            width: 100%;
            height: 50px;

            transform: translateY(-100%);
            opacity: 0;
            transition: all .2s ease-in-out;

            display: flex;
            align-items: center;
            justify-content: center;

            background-color: #A3CA74;
            border-radius: 5px;


            @include mixins.mainfont(500, 18, colors.$black, 17);

            &.active {
                transform: translateY(0);
                opacity: 1;
                transition: all .2s ease-in-out;
            }
        }
    }
    &__form {
        .input {
            margin-bottom: 16px;
        }

        &-buttons {
            margin-top: 21px;
            display: flex;
            column-gap: 24px;
        }
    }

    &__buttons {
        justify-content: flex-end;
    }
}
