.date {
    position: relative;

    & > .icon {
        // color: colors.$light-gray;
        position: absolute;
        right: 13.75px;
        top: 32px;
        z-index: 10;
        pointer-events: none;
    }

    input {
        cursor: pointer;
    }

    .icon-calendar {
        left: 10px;
        visibility: hidden;
    }

    .icon-chevron-up {
        right: 10px;
    }

    .date {
        &__icon {
            pointer-events: none;
            top: 50%;
            transform: translateY(-50%);
            transition: transform 0.2s, fill 0.2s;
        }
    }

    &:not(.active) {
        // .input {
        //     padding: 0 5px 0 40px;
        //     width: calc(100% - 47px);
        // }
        .icon-calendar {
            visibility: visible;
        }
    }

    &:not(.open) {
        .datepickers,
        .datetravel {
            visibility: hidden;
            max-height: 0;
            max-width: 0;
            height: 0;
            width: 0;
            display: none;
        }
    }

    &.open,
    &:hover,
    &.active {
        .input {
            // color: colors.$white;
            // border-color: colors.$white;
        }

        .icon-calendar {
            // fill: colors.$white;
        }
        .icon-chevron-up {
            // fill: colors.$white;
        }
    }

    &.open {
        .icon-chevron-up {
            transform: scale(1, -1) translateY(50%);
        }
    }

    .datetravel {
        box-sizing: border-box;
        position: absolute;
        z-index: 7;
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        height: 50px;
        // right: -1px;
        left: 0;
        margin-top: 3px;
        padding: 0 15px;

        .icon-button {
            left: unset !important;
            right: unset !important;
            position: unset !important;
            width: 24px;
        }

        .icon {
            // color: colors.$light-gray;

            &.left {
                transform: rotate(90deg);
            }
            &.right {
                transform: rotate(-90deg);
            }
        }
    }

    .datepickers {
        position: absolute;
        display: flex;
        z-index: 5;
        left: 0;
        background-color: #ffffff;
        box-shadow: 2px 16px 19px rgba(0, 0, 0, 0.09);
        border-radius: 8px;
        margin-top: 12px;
    }

    .datepicker {
        box-sizing: border-box;
        padding: 16px;
        display: flex;
        flex-flow: column nowrap;
        gap: 4px;
        width: 318px;

        &__header {
            display: flex;
            align-items: center;
            flex-flow: row nowrap;
            gap: 5px;
            justify-content: center;
            padding-bottom: 12px;

            &-month,
            &-year {
                font-family: fonts.$mainFont;
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 14px;
                text-align: center;
                color: #4a5660;
            }

            &-month {
                text-transform: capitalize;
            }
        }

        &__weekdays,
        &__month {
            // font-family: fonts.$inter;
            font-style: normal;

            width: 100%;
            display: grid;
            grid-template-columns: repeat(7, 40px);
            gap: 2px;
            text-align: center;
            row-gap: 4px;
        }

        &__weekdays {
            font-family: fonts.$mainFont;
            font-style: normal;
            font-weight: 400;
            font-size: 10px;
            line-height: 12px;
            text-align: center;
            letter-spacing: 1.5px;
            text-transform: uppercase;
            color: #b5bec6;
        }

        &__month {
            font-family: fonts.$mainFont;
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 14px;
            text-align: center;
            color: #4a5660;
        }

        &__day,
        &__weekday,
        &__day-other {
            box-sizing: border-box;
            display: block;
            width: 100%;
            padding: 5.5px 0 3.5px;
            // background-color: #939cbe;
            // border-radius: 50px;
        }

        &__day {
            font-family: fonts.$mainFont;
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 18px;
            text-align: center;
            color: #4a5660;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 30px;
            height: 30px;

            &:not(.selected):hover {
                background: #9a9a9a;
                border-radius: 2px;
                color: #ffffff;
            }
        }

        // &__d {
        //     &:nth-child(7n),
        //     &:nth-child(7n - 1) {
        //         &:not(.datepicker__weekday):not(.datepicker__day-other) {
        //             // color: colors.$blue;
        //         }
        //     }
        //     &:nth-child(7n) {
        //         border-radius: 0 2px 2px 0;
        //     }
        //     &:nth-child(7n - 6) {
        //         border-radius: 2px 0 0 2px;
        //     }
        // }

        &__day {
            &,
            &-other {
                cursor: pointer;
                user-select: none;
            }

            &-other {
                // color: colors.$gray-3;
            }

            &.selected {
                background: #9a9a9a;
                border-radius: 2px;
                color: #ffffff;
            }

            &.between {
                // background-color: colors.$separator;
            }
        }
    }
}
