.stations-action {
    &__form {
        .stations-input-disabled {
            opacity: 0.6;
        }

        display: flex;
        flex-direction: column;
        row-gap: 12px;

        &-buttons {
            display: flex;
            column-gap: 12px;

            .button {
                max-width: 168px;
            }
        }
    }
}
