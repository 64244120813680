.user-no-select {
    pointer-events: none;

    &_child {
        * {
            pointer-events: none;
        }
    }
}

.fit-content {
    width: fit-content;
    height: fit-content;
}

.import-table-counter {
    color: #000;
    font-family:
        DM Sans,
        sans-serif;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.2857142857;
    opacity: 0.5;
    float: right;
}
