.input-block {
  display: flex;
  flex-direction: column;
  row-gap: 5px;
  position: relative;

  &__label {
    @include mixins.mainfont(500, 13, rgba(colors.$black, 0.5), 17);
    display: flex;
    align-items: center;
    column-gap: 2px;
    width: fit-content;

    span {
      color: colors.$red;
    }
  }

  &.secondary {
    input {
      background-color: colors.$white;
    }

    .input-block__label {
      @include mixins.mainfont(500, 14, colors.$black, 18);
    }
  }

  .icon {
    position: absolute;
    top: 50%;
    right: 15px;
    color: colors.$black;
  }
}
