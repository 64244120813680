
                    @use "src/Styles/basic/colors" as colors;
                    @use "src/Styles/basic/fonts" as fonts;
                    @use "src/Styles/basic/functions" as functions;
                    @use "src/Styles/basic/mixins" as mixins;
                
.checkboxWrapper {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-auto-rows: auto;

  grid-column-gap: 100px;
  grid-row-gap: 20px;

  &__item {
    column-gap: 14px;
  }

  &__text{
    @include mixins.mainfont(500, 14, colors.$black);
    white-space: nowrap;
  }
}

.errors {
  display: inline-block;
  margin-bottom: 5px;
  @include mixins.mainfont(500, 14, colors.$red, 18);
}
