
                    @use "src/Styles/basic/colors" as colors;
                    @use "src/Styles/basic/fonts" as fonts;
                    @use "src/Styles/basic/functions" as functions;
                    @use "src/Styles/basic/mixins" as mixins;
                
.circle_divisions_legend {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 25px;

  @include mixins.mainfont(500, 14, colors.$black);

  &_item {
    width: max-content;
    display: flex;
    gap: 8px;
  }
}
