@font-face {
    font-family: "DM Sans";
    src: url(../../Assets/Fonts/DM_Sans/DMSans-Regular.ttf) format("truetype");
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: "DM Sans";
    src: url(../../Assets/Fonts/DM_Sans/DMSans-Medium.ttf) format("truetype");
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: "DM Sans";
    src: url(../../Assets/Fonts/DM_Sans/DMSans-Bold.ttf) format("truetype");
    font-weight: 700;
    font-style: normal;
}

// @font-face {
//     font-family: "DM Sans";
//     src: url(../../Assets/Fonts/DM_Sans/DMSans-Italic.ttf) format("truetype");
//     font-weight: 400;
//     font-style: italic;
// }

// @font-face {
//     font-family: "DM Sans";
//     src: url(../../Assets/Fonts/DM_Sans/DMSans-MediumItalic.ttf) format("truetype");
//     font-weight: 500;
//     font-style: italic;
// }

// @font-face {
//     font-family: "DM Sans";
//     src: url(../../Assets/Fonts/DM_Sans/DMSans-BoldItalic.ttf) format("truetype");
//     font-weight: 700;
//     font-style: italic;
// }

// @import url("https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap");
