
                    @use "src/Styles/basic/colors" as colors;
                    @use "src/Styles/basic/fonts" as fonts;
                    @use "src/Styles/basic/functions" as functions;
                    @use "src/Styles/basic/mixins" as mixins;
                
.modal {
  width: 450px;
  padding: 30px;

  @include mixins.mainfont(400, 16, colors.$black, 18);

  &_description {
    margin-bottom: 30px;
  }

  &_error {
    color: colors.$red;
  }

  &__actions {
    margin-top: 30px;
    display: flex;
    gap: 10px;
  }
}