.drag-n-drop {
  &__area {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;

    input {
      &.hidden {
        display: none;
      }
    }

    &-error {
      margin-top: 10px;
      @include mixins.mainfont(400, 13, colors.$red, 17);
    }

    &__max-size {
      @include mixins.mainfont(400, 13, rgba(colors.$black, 0.5), 17);
    }

    button {
      font-family: fonts.$mainFont;
      font-style: normal;
      font-weight: 500;
      font-size: 15px;
      line-height: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: colors.$skin;
      border: 1px solid transparent;
      border-radius: 5px;
      color: colors.$brown;
      padding: 13px 0;
      width: 100%;
      cursor: pointer;
      column-gap: 13px;
      transition: color 0.2s;

      &.error {
        border-color: colors.$red;
      }

      .icon {
        color: colors.$brown;
        transition: color 0.2s;
      }

      &:hover {
        color: colors.$brown-dark;
        .icon {
          color: colors.$brown-dark;
        }
      }
    }

    //@include media.big {
    //  // width: 322px;
    //  height: 160px;
    //  //border: 1px solid colors.$gray-3;
    //  border-radius: 4px;
    //  background-color: #292a38;
    //  padding: 0 30px;
    //  transition: border 0.2s;
    //  text-align: center;
    //
    //  &.error {
    //    //border: 1px solid colors.$red;
    //
    //    .text {
    //      //color: colors.$red;
    //    }
    //
    //    .icon {
    //      //fill: colors.$red;
    //      margin-bottom: 2px;
    //    }
    //  }
    //
    //  &.drag {
    //    //border: 1px solid colors.$white;
    //  }
    //}

    //@include media.big {
    //  &-title,
    //  &-accepts {
    //    //color: colors.$gray-3;
    //  }
    //
    //  &-accepts {
    //    margin: 5px 0 18px;
    //    display: block;
    //  }
    //}
    //@include media.small {
    //  &-title,
    //  &-accepts {
    //    display: none;
    //  }
    //}

    // &-drag {
    //   //color: colors.$white;
    // }

    &-button {
      //border: 1px solid colors.$gray-3;
      background-color: transparent;
      //color: colors.$gray-3;

      //@include media.big {
      //  width: 185px;
      //}
      //@include media.small {
      //  width: 100%;
      //}

      // .icon {
      //   //fill: colors.$gray-3;
      // }

      // &:hover {
      //   //background-color: colors.$gray-1;
      // }
    }
  }

  &-file-list {
    position: relative;
    padding: 0 4px;
    margin-top: 10px;

    //@include media.big {
    //  width: calc(100% - 8px);
    //}

    .file {
      display: flex;
      flex-flow: row nowrap;
      gap: 7.5px;

      &__info {
        display: flex;
        flex-flow: column nowrap;
        gap: 2px;
        margin-top: 4px;
      }

      &-name,
      &-size {
        @include mixins.mainfont(400, 13, rgba(colors.$black, 0.5), 17);
      }

      // &-name {
      // }
      // &-size {
      // }
    }

    .icon-close {
      position: absolute;
      right: 0;
      top: 0;
      //fill: colors.$gray-3;
    }
  }

  &-file-example {
    display: flex;
    flex-flow: row nowrap;
    gap: 7.5px;
    align-items: center;
    cursor: pointer;
    width: fit-content;
    margin-top: 25px;

    .text {
      padding-top: 2px;
      display: block;
      //color: colors.$gray-3;
    }

    // .icon {
    //   //fill: colors.$gray-3;
    // }

    // &:hover {
    //   .text {
    //     //color: colors.$white;
    //   }
    //   .icon {
    //     //fill: colors.$white;
    //   }
    // }
  }
}
