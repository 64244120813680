.files-action {
    &__form {
        max-width: 360px;
        display: flex;
        flex-direction: column;
        row-gap: 12px;

        &-buttons {
            display: flex;
            column-gap: 100px;

            a,
            .button {
                width: 100%;
                height: 40px;
                padding: 0;
                display: flex;
                align-items: center;
                justify-content: center;
                text-decoration: none;
            }
        }
        &-error {
            font-family: fonts.$mainFont;
            color: colors.$red;
            text-align: center;
        }
    }
}
