.info {
	padding: 40px 20px 20px;

	& > .icon {
		position: absolute;
		top: 12px;
		right: 12px;
		cursor: pointer;
		color: colors.$brown;
	}
}
