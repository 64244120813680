.import {
    &__column {
        display: flex;
        flex-direction: column;
        gap: 25px;
        align-items: flex-end;

        .import_button {
            width: 130px;
            height: 40px;
            padding: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            text-decoration: none;
            gap: 10px;

            .circle_loader {
                circle {
                    transition: 0.2s;
                }
            }
        }
    }

    &__row {
        display: flex;
        gap: 20px;
        width: 100%;
    }

    &__input {
        flex-grow: 1;

        & > label {
            @include mixins.mainfont(500, 13, rgba(colors.$black, 0.5), 17);

            &::after {
                color: colors.$red;
                content: "*";
            }
        }
    }
}
