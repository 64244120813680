.chart_bars__axis {
  &-left {
    fill: #e2e2e2;
  }

  &-tick {
    &.no_line {
      line {
        display: none;
      }
    }

    &:not(.not_line) {
      line {
        stroke: #e2e2e2;
      }
    }

    text {
      @include mixins.mainfont(500, 14, rgba(colors.$black, 0.5), 18px);

      fill: rgba(colors.$black, 0.5);
    }
  }

  &:not(.horisontal) {
    .chart_bars__axis-tick {
      &.no_line {
        &:nth-last-child(2) {
          text {
            transform: translate(4px, 16px);
          }
        }
      }

      &:not(.not_line) {
        &:nth-last-child(2) {
          line {
            stroke: transparent;
          }
        }
      }

      text {
        transform: translate(4px, 10px);
      }
    }
  }

  &.horisontal {
    .chart_bars__axis-tick {
      text {
        transform: translate(0px, -15px);
      }
    }
  }

  & > .visx-axis-line {
    display: none;
  }
}
