.tabs {
    width: 100%;

    &__list {
        display: flex;
        column-gap: 15px;
        margin-bottom: 31px;
        background-color: colors.$white;
        height: 100%;

        &.additional {
            padding: 0 18px 18px;
            border-radius: 0 0 10px 10px;
            margin-bottom: 15px;
        }
    }

    &__tab {
        padding: 11px 16px;
        background: #ffffff;
        border: 1px solid rgba(182, 127, 127, 0.2);
        border-radius: 8px;
        position: relative;
        cursor: pointer;

        @include mixins.mainfont(500, 14, colors.$brown, 17);
        text-decoration: none;

        &.active,
        &:hover {
            border-color: transparent;
            color: colors.$black;
            background-color: colors.$skin-3;
            transition: border-color, color, background-color 0.2s;
        }
    }
}
