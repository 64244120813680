@use "./fonts" as fonts;
@use "./functions" as functions;

@mixin hideScrollbar {
    &::-webkit-scrollbar {
        display: none;
    }
    -ms-overflow-style: none;
    scrollbar-width: none;
}

@mixin noselect {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}

@mixin font($name, $weight, $size, $color, $height, $style) {
    font-family: $name;
    font-style: $style;
    font-weight: $weight;
    font-size: functions.pxToRem($size);
    color: $color;

    @if $height != 1 {
        line-height: calc($height / $size);
    }
}

@mixin mainfont($weight, $size, $color, $height: 1, $style: normal) {
    @include font(fonts.$mainFont, $weight, $size, $color, $height, $style);
}
