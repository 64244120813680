.orders_action {
    &__form {
        width: 100%;
        display: flex;
        flex-direction: column;
        row-gap: 12px;

        &__inputs {
            display: flex;
            flex-direction: column;
            row-gap: 12px;
            width: 405px;
            margin-bottom: 20px;
        }

        &-buttons {
            display: flex;
            column-gap: 100px;

            a,
            .button {
                width: 100%;
                height: 40px;
                padding: 0;
                display: flex;
                align-items: center;
                justify-content: center;
                text-decoration: none;
            }
        }
        &-error {
            font-family: fonts.$mainFont;
            color: colors.$red;
            text-align: center;
        }

        .products {
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: 20px;

            &__header {
                display: flex;
                justify-content: space-between;

                .button {
                    width: 150px;
                }
            }

            &__list {
                display: flex;
                flex-direction: column;
                gap: 20px;
                width: 100%;

                .item {
                    &__inputs {
                        display: flex;
                        gap: 20px;
                        width: 100%;
                        align-items: flex-end;

                        & > .input {
                            flex-basis: 60%;
                            min-width: 200px;

                            &:first-child {
                                flex-basis: 15%;
                            }
                        }

                        & > .uploader {
                            flex-basis: 15%;
                            min-width: 200px;

                            & > .uploader__items {
                                margin-top: 5px;
                            }
                        }

                        & > .button_wrapper > svg {
                            color: colors.$brown;
                            margin-bottom: 7px;
                        }
                    }
                    &__errors {
                        display: flex;
                        flex-direction: column;
                        margin-bottom: 5px;

                        span {
                            @include mixins.mainfont(500, 14, colors.$black);
                            color: colors.$red;
                        }
                    }
                }
            }
        }
    }
}
