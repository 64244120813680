.stores-action {
  &__form {
    display: flex;
    flex-direction: column;
    row-gap: 12px;
    font-family: fonts.$mainFont;

    &_gps {
      @include mixins.mainfont(500, 13, rgba(colors.$black, 0.5), 17);
      margin-top: 8px;
      margin-bottom: 8px;

      h3 {
        @include mixins.mainfont(500, 16, rgba(colors.$black, 0.5), 17);
        line-height: 1.2;
        margin-bottom: 10px;
      }

      &_coords {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 6px;
      }
    }

    &-buttons {
      display: flex;
      column-gap: 12px;

      a,
      .button {
        width: 100%;
      }
    }
  }
}
