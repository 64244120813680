.login {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: colors.$skin;

    &__form {
        display: flex;
        flex-direction: column;
        max-width: 360px;
        width: 100%;

        h2 {
            margin-top: 72px;
            margin-bottom: 24px;
            font-family: fonts.$mainFont;
            font-weight: 500;
            font-size: 30px;
            line-height: 39px;
            color: colors.$black;
            text-align: center;
        }

        & > p {

            font-family: fonts.$mainFont;
            font-weight: 500;
            font-size: 16px;
            line-height: 21px;
            color: colors.$black;
            text-align: center;
            margin-bottom: 52px;

            &.login__error {
                color: colors.$red;
                margin-bottom: 25px;
            }
        }

        .input {
            margin-bottom: 16px;
        }

        &-container {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 32px;

            a {
                font-family: fonts.$mainFont;
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 18px;
                display: flex;
                align-items: center;
                color: colors.$skin-4;
                text-decoration: none;
            }
        }
    }
    .checkbox__block {
        background-color: colors.$white;
    }
}
