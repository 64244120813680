.dashboard {
    &__wizard-answers {
        height: 379px; // <-- after hiding fields in Work of devices
        .select {
            max-width: 140px;
            margin-bottom: 24px;
        }

        &__list {
            //max-height: 312px; // <-- before hiding fields in Work of devices
            max-height: 208px; // <-- after hiding fields in Work of devices
            //@include mixins.hideScrollbar();

            &.scroll {
                overflow-y: scroll;
                padding-right: 15px;
                &::-webkit-scrollbar {
                    width: 8px;
                }
                &::-webkit-scrollbar-track {
                    background-color: rgba(182, 127, 127, 0.2);
                    border-radius: 10px;
                }
                &::-webkit-scrollbar-thumb {
                    background-color: colors.$brown-dark;
                    border-radius: 10px;
                }
                @-moz-document url-prefix() {
                    scrollbar-width: thin;
                    scrollbar-color: colors.$brown-dark rgba(182, 127, 127, 0.2);
                }
            }
        }

        &__item {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 16px 15px;
            border: 1px solid colors.$light-gray;
            border-radius: 8px;
            @include mixins.mainfont(400, 14, colors.$black, 18);
        }
    }

    &__search {
        padding: 16px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border: 1px solid colors.$light-gray;
        border-radius: 8px;
        @include mixins.mainfont(400, 14, colors.$black, 18);

        &-label {
            display: flex;
            align-items: center;
            column-gap: 12px;
        }
    }

    .board {
        &_item {
            &.sticky.head {
                .filters {
                    margin: 31px 0 0 0;
                }
            }
        }
    }
}
