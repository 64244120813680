.export-block {
    display: flex;
    align-items: center;

    span {
        padding: 0px 28px;
        @include mixins.mainfont(700, 13, colors.$brown, 17);
        text-transform: uppercase;
        cursor: pointer;
        transition: color 0.2s;
        &:not(:last-child) {
            border-right: 2px solid colors.$skin-2;
        }
        &:hover {
            color: colors.$brown-dark;
        }
    }
}
