
                    @use "src/Styles/basic/colors" as colors;
                    @use "src/Styles/basic/fonts" as fonts;
                    @use "src/Styles/basic/functions" as functions;
                    @use "src/Styles/basic/mixins" as mixins;
                
.filtersWrapper {
  display: flex;
  gap: 16px;
}

.filter {
  margin-bottom: 16px;
}

.title {
  margin-bottom: 16px !important;
}