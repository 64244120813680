
                    @use "src/Styles/basic/colors" as colors;
                    @use "src/Styles/basic/fonts" as fonts;
                    @use "src/Styles/basic/functions" as functions;
                    @use "src/Styles/basic/mixins" as mixins;
                
.fragrance_type {
  display: flex;
  align-items: center;
  gap: 20px;
}

.label_value {
  margin: 0 50% 0 auto;
}
