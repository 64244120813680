.page.station {
    .board.station {
        .board_item.head {
            .station {
                &__header {
                    margin-bottom: 20px;
                }
                &__filters {
                    display: flex;
                    gap: 20px;

                    .switcher {
                        width: 240px;
                    }
                }
            }
        }
    }
    // padding: 50px 58px;
    // width: 100%;
}
