.datepicker {
    position: relative;
    z-index: 1;

    &_required {
        width: 100%;

        .datepicker {
            &__input {
                input {
                    &::placeholder {
                        &::after {
                            color: colors.$red;
                            content: "*";
                        }
                    }
                }
            }
        }
    }

    &__inputs {
        @import "./Input/styles";

        display: flex;
        gap: 12px;
    }

    &__block {
        opacity: 0;
        visibility: hidden;
        transition: 0.2s;

        &.open {
            opacity: 1;
            visibility: visible;
        }
    }

    &-errors {
        display: flex;
        flex-direction: column;
        margin-bottom: 5px;

        p {
            @include mixins.mainfont(500, 14, colors.$black);
            // text-align: center;
            color: colors.$red;
        }
    }

    &_content {
        position: absolute;
        top: calc(100% + 14px);
        width: 355px;
        // height: 488px;
        background-color: colors.$white;
        border: 1px solid colors.$light-gray;
        box-shadow: 0px 8.26788px 33.0715px rgba(0, 0, 0, 0.05);
        border-radius: 20px;
        padding: 35px 22px 22px;
        z-index: 1;

        &__inputs {
            display: flex;
            gap: 9px;
            justify-content: space-between;
            margin-bottom: 30px;

            & > input {
                width: calc(100% / 3) !important;
                height: 40px;
            }
        }

        &__header {
            display: flex;
            justify-content: space-between;
            align-items: center;

            p {
                @include mixins.mainfont(500, 18, colors.$black);
            }

            &-date {
                width: 180px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                gap: 20px;

                p {
                    text-transform: uppercase;
                }
            }

            &-icon {
                &_left {
                    transform: rotate(-180deg);
                }
            }

            padding-bottom: 25px;
            border-bottom: 2px solid colors.$light-gray;
        }

        &__calendar {
            padding-top: 25px;

            &-weekdays,
            &-days {
                display: grid;
                grid-template-columns: repeat(7, 30px);
                column-gap: 17px;
                row-gap: 20px;

                span {
                    width: 30px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }

            &-weekdays {
                span {
                    @include mixins.mainfont(700, 16, #a16464);

                    height: 20px;
                }
            }

            &-days {
                margin-top: 25px;

                span {
                    @include mixins.mainfont(700, 16, colors.$black);

                    height: 30px;
                    border-radius: 50%;
                    position: relative;
                    transition: 0.2s;
                    cursor: pointer;

                    &.offset {
                        color: rgba(0, 0, 0, 0.3);
                    }

                    &::before,
                    &::after {
                        opacity: 0;
                        transition: 0.2s;
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        content: "";
                    }

                    &::before {
                        width: 0;
                        height: 0;
                        border-radius: 50%;
                        z-index: -1;
                    }

                    &::after {
                        width: 34px;
                        height: 34px;
                        z-index: -2;
                    }

                    &:hover,
                    &.selected {
                        color: colors.$white;

                        &::before {
                            width: 34px;
                            height: 34px;
                            opacity: 1;
                            background-color: #c17e7e;
                        }
                    }

                    &.selected:not(:nth-child(7n)),
                    &.inrange {
                        & + .inrange,
                        & + .selected {
                            // &:not(.selected:nth-child(7n)):not(.selected::nth-child(7n + 1))

                            &::after {
                                // background-color: red;
                                background-color: colors.$skin;
                                opacity: 1;
                                padding-left: 30px;
                                border-radius: 50px 0 0 50px;
                                transform: translate(-100%, -50%);
                            }
                        }

                        // & + .selected {
                        //     &::after {
                        //         // border-radius: 0 50px 50px 0;
                        //     }
                        // }
                    }

                    &.selected {
                        &::after {
                            border-radius: 50px 0 0 50px;
                        }
                    }

                    &:not(.selected):not(.inrange) {
                        & + .selected + .selected:not(:nth-child(7n + 1)) {
                            &::after {
                                border-radius: 50px 0 0 50px;
                            }
                        }
                    }

                    &.inrange {
                        &:nth-child(7n) {
                            &::after {
                                border-radius: 0;
                                transform: translate(-80%, -50%) !important;
                            }

                            &.selected {
                                &::after {
                                    border-radius: 0 50px 50px 0;
                                }
                            }
                        }

                        &:nth-child(7n + 1) {
                            &:not(.selected) {
                                &::after {
                                    border-radius: 0;
                                }
                            }

                            &.selected {
                                &::after {
                                    border-radius: 0 50px 50px 0;
                                }
                            }

                            &::after {
                                padding: 0;
                                transform: translate(-50%, -50%);
                            }
                        }
                    }
                }
            }
        }
    }

    &_single {
        width: 100%;

        .datepicker {
            &__inputs,
            &__input {
                width: 100%;
                height: 46px;
            }
        }
    }
}
