.password-recovery-success {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: colors.$skin;
  &__form {
    display: flex;
    flex-direction: column;
    max-width: 360px;
    width: 100%;

    h2 {
      margin-top: 72px;
      margin-bottom: 24px;
      font-family: fonts.$mainFont;
      font-weight: 500;
      font-size: 30px;
      line-height: 39px;
      color: colors.$black;
      text-align: center;
    }

    & > p {

      font-family: fonts.$mainFont;
      font-weight: 700;
      font-size: 16px;
      line-height: 21px;
      color: colors.$black;
      text-align: center;
      margin-bottom: 37px;
    }

    & > a {
      margin: 0 auto;
      width: fit-content;
      display: flex;
      align-items: center;

      font-family: fonts.$mainFont;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 22px;
      color: colors.$black;
      text-decoration: none;

      .icon {
        color: colors.$brown;
      }
    }

    .input {
      margin-bottom: 16px;
    }

    .checkbox {
      margin-bottom: 32px;
      &__block {
        background-color: colors.$white;
      }
    }
  }
}