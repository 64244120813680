.station {
    &_statistics {
        display: flex;
        justify-content: space-between;
        gap: 20px;
        align-items: center;
        margin-top: 25px;

        &__items {
            display: grid;
            grid-template-columns: 1fr 1fr;
            column-gap: 15px;
            row-gap: 0;
            // align-content: center;
            max-height: 250px;
            width: 100%;
            overflow-y: scroll;

            @include mixins.hideScrollbar;
        }

        &__item {
            height: 50px;
            padding: 0 11px;
            display: flex;
            align-items: center;
            gap: 12px;
            border: 1px solid #f0f0f0;
            border-radius: 8px;
            width: 100%;

            &.first {
                grid-column: 1;
                // grid-row: 1;
            }

            &.second {
                grid-column: 2;
                // grid-row: 2;
            }

            &-circle {
                width: 14px;
                height: 14px;
                border-radius: 50%;
            }

            &-name {
                @include mixins.mainfont(400, 14, colors.$black, 18);
            }
        }
    }

    &_device {
        &__products {
            margin-top: 20px;
            display: flex;
            flex-direction: column;
            gap: 10px;
            max-height: 140px;
            &.scroll {
                overflow-y: scroll;
                padding-right: 15px;
                &::-webkit-scrollbar {
                    width: 8px;
                }
                &::-webkit-scrollbar-track {
                    background-color: rgba(182, 127, 127, 0.2);
                    border-radius: 10px;
                }
                &::-webkit-scrollbar-thumb {
                    background-color: colors.$brown-dark;
                    border-radius: 10px;
                }
                @-moz-document url-prefix() {
                    scrollbar-width: thin;
                    scrollbar-color: colors.$brown-dark rgba(182, 127, 127, 0.2);
                }
            }
            //@include mixins.hideScrollbar;

            //display: grid;
            //grid-template-columns: 1fr 5fr;
        }

        &__product {
            display: flex;
            align-items: center;
            gap: 17px;
            justify-content: space-between;

            &-name {
                @include mixins.mainfont(500, 14, colors.$black, 18);
                width: 22%;
                max-width: 70px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            &-line {
                position: relative;
                height: 20px;
                width: 100%;
                background-color: #f0f0f0;
                border-radius: 20px;
                overflow: hidden;

                div {
                    height: 100%;
                    position: absolute;
                    border-radius: 50px;
                    top: 50%;
                    background-color: #bccc80;
                    transform: translateY(-50%);
                    min-width: 1%;
                }
                span {
                    position: absolute;
                    z-index: 1;
                    @include mixins.mainfont(500, 12, colors.$black, 16);
                    top: 50%;
                    transform: translate(-50%, -50%);
                    right: 9px;
                }
            }
        }
    }

    &__device {
        &-title {
            margin-bottom: 19px !important;
        }

        &-statistics {
            display: flex;
            flex-direction: column;
            row-gap: 10px;

            &__item {
                display: flex;
                align-items: center;
                gap: 17px;

                p {
                    width: fit-content;
                    @include mixins.mainfont(500, 14, colors.$black, 18);
                }

                &-line {
                    background-color: #f0f0f0;
                    border-radius: 20px;
                    height: 20px;
                    width: 100%;
                    position: relative;
                    display: flex;
                    justify-content: flex-end;
                    align-items: center;

                    span {
                        position: relative;
                        padding: 3px;
                        z-index: 1;
                        margin-right: 9px;
                        @include mixins.mainfont(500, 12, colors.$black, 16);
                    }
                }
            }
        }
    }
}
