$skin: #f6efe9;
$skin-2: #e9dcd0;
$skin-3: #f0e3d8;
$skin-4: #B67F7F;
$skin-light: #FFFBF8;
$brown: #c17e7e;
$brown-dark: #A26464;
$white: #ffffff;
$light-gray: #f0f0f0;
$dark-gray: #2e2e2e;
$black: #000000;
$red: #EC6B77;