.input {
    &.error {
        input,
        label {
            color: colors.$red !important;
        }
    }
    &.select {
        &.absolute {
            position: relative;
            .select__options {
                width: 100%;
                margin-top: 0;
                position: absolute;
                top: calc(100% + 10px);
                left: 0;
            }
        }
        &.white {
            input {
                background-color: colors.$white;
                border: 1px solid rgba(182, 127, 127, 0.2);
            }
            .select__options {
                background-color: colors.$white;
                border: 1px solid transparent;
                visibility: hidden;
            }
            &.open {
                .select__options {
                    visibility: visible;
                    border: 1px solid rgba(182, 127, 127, 0.2);
                }
            }
        }
    }
    &.disabled {
        opacity: 0.5;
        pointer-events: none;
        cursor: not-allowed;
    }
    input,
    textarea {
        width: 100%;
        // margin-bottom: 16px;
        padding: 13px 12px;
        border: none;
        border-radius: 5px;
        background: colors.$skin;

        @include mixins.mainfont(500, 15, colors.$black, 20);

        outline: none;

        &::placeholder {
            @include mixins.mainfont(500, 14, colors.$black, 18);
            opacity: 0.5;
        }
    }

    textarea {
        resize: none;
    }

    &__content {
        position: relative;
    }
}
