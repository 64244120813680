
                    @use "src/Styles/basic/colors" as colors;
                    @use "src/Styles/basic/fonts" as fonts;
                    @use "src/Styles/basic/functions" as functions;
                    @use "src/Styles/basic/mixins" as mixins;
                
.switcher {
  display: flex;
  position: relative;
  column-gap: 16px;
  justify-content: space-between;
  align-items: center;
  padding: 5px 13px;
  background-color: colors.$skin-3;
  border-radius: 5px;
  height: 46px;

  &__item {
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
    cursor: pointer;
    padding: 0 14px;
    height: 100%;
    @include mixins.mainfont(500, 14, colors.$brown, 18);
    transition: 0.2s;
  }

  & .active {
    background-color: colors.$white;
    border-radius: 5px;
    transition: 0.2s;
  }

  &__errors {
    border: 1px solid colors.$red;
  }
}

.errors {
  display: inline-block;
  margin-bottom: 5px;
  @include mixins.mainfont(500, 14, colors.$red, 18);
}