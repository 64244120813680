.input-block {
    position: relative;

    .react-international-phone-input-container {
        display: flex;

        .react-international-phone-country-selector {
            &-button {
                cursor: pointer;
                display: flex;
                align-items: center;
                justify-content: flex-start;
                height: 100%;
                width: 40px;
                padding: 0 5px;
                background-color: colors.$white;
                @include mixins.mainfont(500, 14, colors.$black, 18);
            }

            &-dropdown {
                position: absolute;
                z-index: 21;
                top: 0;
                left: 0;
                display: flex;
                flex-direction: column;
                row-gap: 19px;
                background-color: colors.$skin;
                max-width: 300px;
                max-height: 380px;
                border-radius: 5px;
                overflow-y: auto;
                outline: none;
                padding: 24px 10px;
                border: 1px solid rgba(0, 0, 0, 0.05);
                box-shadow: 1px 0 20px rgba(0, 0, 0, 0.1);
                visibility: visible;
                opacity: 1;

                &__list-item {
                    padding: 9px 27px 9px 24px;
                    display: flex;
                    align-items: center;
                    column-gap: 18px;
                    cursor: pointer;
                    border-radius: 8px;
                    transition: background-color 0.2s;
                    @include mixins.mainfont(400, 18, colors.$black, 23);

                    & > img {
                        flex-shrink: 0;
                        width: 22px;
                        height: 22px;
                    }

                    &-country-name {
                        max-width: 100px;
                        flex-grow: 1;
                        flex-shrink: 1;
                    }

                    & > span {
                        text-overflow: ellipsis;
                        overflow: hidden;
                        white-space: nowrap;
                        flex-shrink: 0;
                    }

                    &:hover {
                        background-color: rgba(182, 127, 127, 0.49);
                    }
                }
            }
        }
    }
}
