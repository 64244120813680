.textarea {
  .input__content {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }

  &.error {
    textarea {
      border: 1px solid colors.$red;
    }
    label {
      color: colors.$red;
    }
  }

  label {
    @include mixins.mainfont(500, 13, rgba(colors.$black, 0.5), 17);
  }
}
