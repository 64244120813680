@keyframes errorAnimation {
    0% {
        transform: rotate(0deg) scale(1);
    }
    20% {
        transform: rotate(-3deg) scale(1.05);
    }
    70% {
        transform: rotate(3deg) scale(0.95);
    }
    100% {
        transform: rotate(0deg) scale(1);
    }
}

.uploader {
    &-icon {
        color: colors.$brown;
    }

    &.disabled {
        cursor: default;
        pointer-events: none;
    }

    &__header {
        p {
            @include mixins.mainfont(400, 13, rgba(colors.$black, 0.5), 17);

            &.uploader-error {
                color: colors.$red;
            }
        }
    }

    &.image {
        .uploader {
            &__loader {
                width: 100%;
                height: 8px;
                background-color: rgba(colors.$skin-4, 0.2);

                &,
                &-current {
                    border-radius: 8px;
                }

                &-current {
                    transition: width 0.2s;
                    height: 100%;
                    width: 0%;
                    background-color: colors.$brown;
                }
            }

            &__item {
                width: 80px;
                height: 100px;
                border-radius: 5px;
                position: relative;

                p {
                    @include mixins.mainfont(400, 13, colors.$brown, 17);
                }

                img {
                    transition: opacity 0.2s;
                }

                &_upload {
                    border: 1px dashed colors.$brown;
                    cursor: pointer;
                    transition: 0.2s;
                    gap: 5px;

                    &:active {
                        transform: scale(0.95);
                    }

                    &.dragging {
                        background-color: colors.$white;
                        border-style: solid;
                    }

                    &.error {
                        background-color: colors.$white;
                        border-style: dashed;
                        border-color: colors.$red;
                        animation: errorAnimation 0.2s;

                        p {
                            text-align: center;
                            color: colors.$red;
                        }
                    }
                }

                &_file {
                    // padding: 4px;
                    border: 1px solid rgba(colors.$skin-4, 0.2);

                    img {
                        width: 100%;
                        height: 100%;
                        border-radius: 5px;
                        object-fit: contain;
                        opacity: 1;
                    }

                    &-remove {
                        position: absolute;
                        top: 4px;
                        right: 4px;
                        transition: 0.2s;

                        &:active {
                            transform: scale(0.92);
                        }
                    }
                }

                &_upload,
                &_loading {
                    background-color: #f6efe9;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    padding: 10px;
                    flex-direction: column;
                }

                &_loading {
                    gap: 9px;
                    padding-top: 18px;

                    img {
                        height: 0;
                        width: 0;
                        opacity: 0;
                    }
                }
            }

            &__items {
                margin-top: 17px;
                display: flex;
                flex-wrap: wrap;
                gap: 15px;
            }
        }
    }

    &.file {
        .uploader {
            &__loader {
                width: 100%;
                height: 8px;
                background-color: rgba(colors.$skin-4, 0.2);
                flex-shrink: 0;

                &,
                &-current {
                    border-radius: 8px;
                }

                &-current {
                    transition: width 0.2s;
                    height: 100%;
                    width: 0%;
                    background-color: colors.$brown;
                }
            }

            &__item {
                width: 100%;
                border-radius: 5px;
                position: relative;
                background-color: transparent;

                p {
                    @include mixins.mainfont(400, 13, colors.$brown, 17);
                }

                img {
                    transition: opacity 0.2s;
                }

                &_file {
                    border: 1px solid rgba(colors.$skin-4, 0.2);

                    img {
                        width: 100%;
                        height: 100%;
                        border-radius: 5px;
                        object-fit: contain;
                        opacity: 1;
                    }

                    &-remove {
                        position: absolute;
                        top: 4px;
                        right: 4px;
                        transition: 0.2s;

                        &:active {
                            transform: scale(0.92);
                        }
                    }

                    p {
                        max-width: 80%;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                    }
                }

                &_file,
                &_upload,
                &_loading {
                    height: 45px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    padding: 10px;
                    flex-direction: column;
                }

                &_loading {
                    gap: 9px;
                    padding-top: 18px;
                    border: 1px dashed rgba(colors.$skin-4, 0.2);

                    img {
                        height: 0;
                        width: 0;
                        opacity: 0;
                    }
                }

                &_upload {
                    height: 45px;
                    font-family: fonts.$mainFont;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 15px;
                    line-height: 20px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background-color: colors.$skin;
                    border: 1px solid transparent;
                    border-radius: 5px;
                    color: colors.$brown;
                    padding: 13px 0;
                    width: 100%;
                    cursor: pointer;
                    column-gap: 13px;
                    transition: color 0.2s;
                    flex-direction: row;

                    & > svg {
                        color: colors.$brown;
                    }

                    &:active {
                        transform: scale(0.95);
                    }

                    &.dragging {
                        background-color: colors.$white;
                        border-style: solid;
                        border-color: rgba(colors.$skin-4, 0.2);
                    }

                    &.error {
                        background-color: colors.$white;
                        border-style: dashed;
                        border-color: colors.$red;
                        animation: errorAnimation 0.2s;

                        p {
                            text-align: center;
                            color: colors.$red;
                        }
                    }
                }
            }

            &__items {
                margin-top: 17px;
                display: flex;
                flex-wrap: wrap;
                gap: 15px;
            }
        }
    }

    .hidden {
        display: none;
    }
}
