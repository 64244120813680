
                    @use "src/Styles/basic/colors" as colors;
                    @use "src/Styles/basic/fonts" as fonts;
                    @use "src/Styles/basic/functions" as functions;
                    @use "src/Styles/basic/mixins" as mixins;
                
.wrapper {
  display: flex;
  flex-direction: column;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.footer {
  align-self: flex-end;
  margin-bottom: 100px;
}

.form {
  display: flex;
  flex-direction: column;
  row-gap: 12px;

  margin-bottom: 32px;
}

