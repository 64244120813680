.switcher {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  position: relative;
  column-gap: 16px;
  align-items: center;
  padding: 5px 13px;
  background-color: colors.$skin-3;
  border-radius: 5px;
  max-width: 240px;
  height: 46px;

  &__item {
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
    cursor: pointer;
    padding: 0 14px;
    height: 30px;
    @include mixins.mainfont(500, 14, colors.$brown, 18);
  }

  &__selector {
    background-color: colors.$white;
    border-radius: 5px;
    height: calc(100% - 10px);
    top: 50%;
    transform: translate(0, -50%);
    width: 70px;
    position: absolute;
    z-index: 0;
    transition: 0.2s;

    &.day {
      left: 15px;
    }

    &.month {
      left: 50%;
      transform: translate(-50%, -50%);
    }

    &.year {
      left: calc(70% - 15px);
    }
  }
}
