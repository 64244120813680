.feedback {
    padding: 23px 21px;
    width: 405px;
    position: relative;

    & > .icon {
        position: absolute;
        top: 12px;
        right: 12px;
        cursor: pointer;
        color: colors.$brown;
    }

    h1 {
        text-align: center;
    }

    &__form {
        display: flex;
        flex-direction: column;
        row-gap: 20px;

        .gray-border {
            margin-top: 4px;
        }

        .gray {
            max-width: 168px;
            margin: 24px auto 0;
        }

        textarea {
            min-height: 180px;
        }

        .button {
            margin-top: 4px;
            height: 40px;
            padding: 0;
        }
    }
}
