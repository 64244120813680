.unsaved {
    padding: 23px 21px;
    width: 405px;
    position: relative;

    & > .icon {
        position: absolute;
        top: 12px;
        right: 12px;
        cursor: pointer;
        color: colors.$brown;
    }

    h1 {
        text-align: center;
    }

    &__buttons {
        display: flex;
        justify-content: space-between;
        margin-bottom: 15px;
        gap: 10px;

        .button {
            width: 130px;
            height: 40px;
        }
    }

    &__list {
        display: flex;
        flex-direction: column;
        gap: 5px;
        margin-bottom: 20px;

        &::before {
            content: "";
            display: block;
            width: 100%;
            margin: 0 0 15px;
            height: 1px;
            background-color: colors.$brown-dark;
            transform: translateY(-50%);
        }

        @include mixins.mainfont(500, 16, colors.$dark-gray);
    }

    .button {
        height: 40px;
        width: 100%;
        padding-top: 0;
        padding-bottom: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        text-transform: none;
        font-size: 13px;
        font-weight: 700;
    }
}
