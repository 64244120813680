.header {
    padding: 4px 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    // margin-bottom: 45px;
    background-color: colors.$white;

    &__heading {
        @include mixins.mainfont(400, 14, colors.$black, 17);
    }

    &__actions {
        display: flex;
        align-items: center;
        // column-gap: 16px;
        & > p {
            @include mixins.mainfont(500, 16, colors.$black, 21);
        }

        & > div {
            display: flex;
            align-items: center;
            column-gap: 10px;
            padding: 6px 15px;
            &.header__lang {
                border-right: 2px solid colors.$skin-2;
            }
        }

        &_action {
            transition: background-color 0.2s;
            border-radius: 8px;
            padding: 10px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;

            &:hover,
            &.active {
                background-color: colors.$skin-3;
            }
        }

        & > span,
        a {
            display: flex;
            align-items: center;
        }

        .icon {
            color: colors.$black;
            cursor: pointer;
        }

        a {
            font-weight: 700;
            color: colors.$brown;
            text-decoration: none;
        }
    }

    &__lang {
        span {
            column-gap: 10px;
        }
    }

    &__log-out {
        padding: 6px;
        border-left: 2px solid colors.$skin-2;
        transition: color 0.2s;
        @include mixins.mainfont(700, 14, colors.$brown, 18);
        text-transform: uppercase;
        cursor: pointer;
        &:hover {
            color: colors.$brown-dark;
        }
    }
}
