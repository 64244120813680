
                    @use "src/Styles/basic/colors" as colors;
                    @use "src/Styles/basic/fonts" as fonts;
                    @use "src/Styles/basic/functions" as functions;
                    @use "src/Styles/basic/mixins" as mixins;
                
.analytics_filters {
  display: flex;
  justify-content: space-between;
  align-items: center;

  &_buttons {
    display: flex;
    gap: 10px;
  }
}