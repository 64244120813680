
                    @use "src/Styles/basic/colors" as colors;
                    @use "src/Styles/basic/fonts" as fonts;
                    @use "src/Styles/basic/functions" as functions;
                    @use "src/Styles/basic/mixins" as mixins;
                
.edit_license_key_modal {
  width: 400px;

  &_actions {
    margin-top: 40px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 15px;
  }
}