
                    @use "src/Styles/basic/colors" as colors;
                    @use "src/Styles/basic/fonts" as fonts;
                    @use "src/Styles/basic/functions" as functions;
                    @use "src/Styles/basic/mixins" as mixins;
                
.clustersWrapper {
  display: flex;
  flex-direction: column;
  row-gap: 12px;

  margin-bottom: 32px;
}

.content {
  display: flex;
  flex-direction: column;
  row-gap: 18px;

  background-color: colors.$white;
  padding: 22px 20px 30px;
  border-radius: 10px;

  width: fit-content;
  min-width: 700px;
  min-height: 350px;
}

.inputWrapper {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}

.add {
  width: 100px;
  height: 46px;

}

.input {
  min-width: 322px;
}