.icon {
    //cursor: pointer;
    transition: opacity .2s;
    &.hoverable {
        cursor: pointer;
    }
    &.opacity,
    &.disabled {
        opacity: 0.5;
    }

    &.disabled {
        cursor: not-allowed;
    }

    use {
        color: inherit;
        fill: transparent;
    }
}