
                    @use "src/Styles/basic/colors" as colors;
                    @use "src/Styles/basic/fonts" as fonts;
                    @use "src/Styles/basic/functions" as functions;
                    @use "src/Styles/basic/mixins" as mixins;
                
.root {
    display: flex;
    align-items: flex-end;
    gap: 16px;
  & > div {
    width: 100%;
  }
}

.trashWrapper {
  display: flex;
  width: 100%;
  gap: 16px;

  & > div {
    width: 100%;
  }
}

.trash {
  position: relative;
  top: 10px
}
