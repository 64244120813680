.page {
    display: grid;
    grid-template-columns: 249px 1fr;
    background-color: colors.$skin;
    min-height: 100vh;
    &__container {
        width: 100%;
    }

    &__content {
        //width: fit-content;
        margin: 45px 24px;
        padding: 22px 19px;
        border-radius: 10px;
        background-color: colors.$white;

        &.small {
            //width: fit-content;
            min-width: 405px;
            width: 405px;
        }
        &.medium {
            width: 640px;
        }

        &.nobg {
            background-color: transparent;
        }
    }
    &-table__footer {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
}
